@import '../globals.scss';

.home {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $page-height;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    flex-direction: column;
    padding: 40px 0;
    height: auto;
  }


  &__left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background-color: red;
    height: 100%;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      width: 100%;
    }

    .title {
      margin-bottom: 2vh;
    }

    .subtitles-container {
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      padding-left: 20px;
      border-bottom-left-radius: 20px;
    }

    &__content {
      margin-top: 10vh;

      h2 {
        margin-bottom: 2vh;
      }


      &__list {
        display: flex;
        flex-direction: column;

        &--link {
          text-decoration: none;
          color: $white;
          font-size: 1.2rem;
          margin-bottom: 2vh;
          position: relative;

          &::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            background-image: url('../../../public/images/icons/arrow-right.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-right: 10px;
          }

          &--bold {
            font-weight: 500;

            &::before {
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              background-image: url('../../../public/images/icons/arrow-right.svg');
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  &__right {
    width: 50%;
    height: 100%;
    position: relative;

    .model-title{
      position: absolute;
      top: 40%;
      right: -5vw;
      transform: rotate(90deg);
      color: $white;
      font-size: 8rem;
      font-weight: 900;
      opacity: 0.5;
      margin-right: -40px;
      letter-spacing: 40px;
    }

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      width: 100%;
      height: 50vh;
    }

    @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
      width: 90%;
      margin-top: 5vh;
    }

    img {
      max-width: 100%;
    }
  }
}