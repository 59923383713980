@import '../../globals.scss';

.dashboard{
  @extend .global-page;
  height: auto;

  .content{
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    padding-bottom: 10vh;

    &__right, &__left{
      width: 50%;
      display: flex;
      flex-direction: column;
      padding-top: 5vh;
    }

    &__left{
      padding-right: 50px;

      .stats{
        .title{
          font-size: 1.5rem;
          font-weight: 700;
        }

        .stats-row{
          display: flex;
          flex-direction: row;
        }
      }
    }

    &__right{
      padding-left: 50px;
      
      .title{
        font-size: 1.5rem;
        font-weight: 700;
      }

      .controls{
        display: flex;
        flex-direction: column;
       
        .controls-buttons{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .button-link {
          text-transform: uppercase;
          cursor: pointer;
          text-decoration: none;
          border: 1px solid $white;
          padding: 8px 10px 5px 10px;
          margin-right: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 