@import '../globals.scss';

.alert{
  position: absolute;
  bottom: 50px;
  left: 0;
  max-width: 30%;
  background-color: red;
  border-radius: 10px;
  padding: 10px;
}