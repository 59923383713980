@import '../../globals.scss';

.ressources-list-page{
  @extend .global-page;

  .list{
    max-height: 65vh;
    overflow: auto;
    padding-right: 50px;
  }
}