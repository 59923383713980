@import '../globals.scss';

.gallery {
  @extend .global-page;
  padding-bottom: 10vh;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    display: none;
  }

  .filters{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .filter{
      margin-right: 20px;
      font-size: 1rem;
      padding: 5px 10px;
      border: 1px solid $white;
      background-color: $black;

      &:hover{
        cursor: pointer;
        background-color: $white;
        color: $black;
      }

      &.active{
        background-color: $white;
        color: $black;
      }
    }
  }
}

.gallery-mobile {
  display: none;

  .link {
    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url('../../../public/images/icons/arrow-right.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 10px;
    }
  }

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    display: block;
    height: $page-height;
  }
}