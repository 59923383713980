@import '../../globals.scss';

.profile-page{
  @extend .global-page;
  padding-bottom: 10vh;

  &__infos{
    margin-bottom: 20px;

    &__item{
      font-size: 1.2rem;
    }
  }

  .btn{
    border: 1px solid $white;
    padding: 10px 20px;
    border-radius: 5px; 
    color: $white;
    text-decoration: none;
    font-weight: 600;

    &.logout{
      border: 1px solid red;
      color: red;
    }
  }
}