@import '../../../../globals.scss';
@import '../../../../pages/app/app.scss';

.air-quality{
  @extend .dashboard-box;
  // flex: 1;

  p{
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }

  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    gap: 10px;
  }

  .title{
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1rem;
    border-bottom: 1px solid $white;
  }

  .separator{
    height: 1px;
    background-color: #FFF;
    width: 100%;
  }

  .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    &__up{
      display: flex;
      flex-direction: column;
      flex: 1;
      // background-color: red;
      width: 100%;
    }
    
    &__bottom{
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      width: 100%;
      // background-color: blue;
    }
  }
}