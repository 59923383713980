@import '../globals.scss';

.privacy {
  @extend .global-page;

  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 2vh;
  }

  h3 {
    margin-bottom: 1vh;
  }

  ul {
    list-style-type: lower-latin;
    list-style-position: inside;

    li {
      margin-bottom: 10px;
    }
  }

  .text {
    // margin: 0;
    // padding: 0;
    font-size: 1rem;
  }
}