@import '../../../../globals.scss';
@import '../../../../pages/app/app.scss';

.daily-forecast{
  @extend .dashboard-box;
   display: flex;
   flex-direction: column;
   flex: 1;
   overflow: auto;

  .title{
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1rem;
    border-bottom: 1px solid $white;
    margin-bottom: 0;
  }

  .forecast{
    margin-top: 20px;
    overflow: auto;
    height: 100%;

    .day{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-width: 100px;
      align-items: center;
      height: 80px;
      border-bottom: 1px solid $white;
      // padding-left: 10px;
      padding-right: 10px;

      img{
        max-width: 35px;
        margin-bottom: 10px;
      }

      .wind{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        .wind-dir{
          position: relative;
          width: 20px;
          height: 20px;

          .text{
            transform-origin: top left;
          }
        }

        .north{
          position: absolute;
          background-color: red;
          border-radius: 3px;
          height: 3px;
          width: 3px;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &:last-child{
        border-bottom: none;
      }
    }


  }

  .text{
    font-size: 1rem;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;

    &.day_number{
      text-align: center;
    }
  }
}