@import '../../globals.scss';

.dashboard-list {
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 10px;
    color: $white;
    border: none;
  }

  th {
    background-color: $black;
    text-align: left;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tbody tr {
    border-bottom: 1px solid $white;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba($white, 0.1);
    }
  }

  .dashboard-list-item__property__image {
    width: 50px;
    display: block;
    margin: auto;
  }

  .dashboard-list-item__property__value {
    font-size: 20px;
  }

  .dashboard-list-item__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    height: 100%;
  }

  .dashboard-list-item__actions__action {
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    background-color: rgba($white, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    &:hover {
      background-color: rgba($white, .5);
    }

    &--red {
      background-color: $red;

      &:hover {
        background-color: rgba($red, .5);
      }
    }
  }
}