@import '../globals.scss';

.copy-badge {
  position: absolute;
  bottom: 0;
  right: 150px;
  color: $white;
  opacity: 50%;
  font-size: 16px;
  z-index: 100;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    right: 50%;
    transform: translateX(50%);
  }
}