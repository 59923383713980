@import '../globals.scss';

.hub {
  @extend .global-page;

  .tools-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      justify-content: space-evenly;
    }
  }
}