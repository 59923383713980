@import '../../globals.scss';

.dashboard-add-ressource {
  @extend .global-page;

  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 10vh;

  @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
    flex-direction: column;
  }

  &__content{
    display: flex;
    flex-direction: row;

    .left{
      width: 40%;

      .types-container{
        margin-bottom: 20px;
        .types-title{
          font-weight: bold;
          font-size: 1.2rem;
        }

        .type-selector{
          margin-right: 20px;
          font-size: 1rem;
          padding: 5px 10px;
          border: 1px solid $white;
          background-color: $black;

          &:hover{
            cursor: pointer;
            background-color: $white;
            color: $black;
          }

          &.active{
            background-color: $white;
            color: $black;
          }
        }
      }


      .additionnal-property{
        .title{
          font-weight: bold;
          font-size: 1.2rem;
        }
      }

      .property-input-container{
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        align-items: flex-end;

        button{
          text-transform: uppercase;
          cursor: pointer;
          border: 1px solid $white;
          padding: 8px 10px 5px 10px;
          background-color: $black;
          margin-left: 20px;
          width: 40%;
          font-size: .8rem;

          .loader{
            border-width: 2px;
            width: 10px;
            height: 10px;
          }

          &:hover{
            background-color: $white;
            color: $black;

            .loader{
              border-color: $black;
              border-bottom-color: transparent;
            }
          }
        }
      }

      .added-properties{
        border: 2px dashed #555;
        min-height: 8vh;
        max-height: 25vh;
        overflow: auto;
        padding: 5px 10px 5px 10px;
      }

      .submit-button{
        margin-top: 5vh;
        text-transform: uppercase;
        cursor: pointer;
        border: 1px solid $white;
        padding: 8px 10px 5px 10px;
        background-color: $black;
        width: 100%;

        .loader{
          border-width: 2px;
          width: 10px;
          height: 10px;
        }

        &:hover{
          background-color: $white;
          color: $black;

          .loader{
            border-color: $black;
            border-bottom-color: transparent;
          }
        }
      }
    }
    
    .right{
      width: 60%;
      padding-left: 60px;
      
      .drop-container {
        position: relative;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        padding: 20px;
        border-radius: 10px;
        border: 2px dashed #555;
        color: #444;
        cursor: pointer;
        
        &:hover {
          background: #111;
        }
        
        input[type=file]::file-selector-button {
          margin-right: 20px;
          border: 1px solid $white;
          background: transparent;
          color: $white;
          padding: 10px 20px;
          cursor: pointer;
          transition: background .2s ease-in-out;
          text-transform: uppercase;
        }

        input[type=file]::file-selector-button:hover {
          background: $white;
          color: $black;
        }

        .image{
          max-height: 40vh;
          max-width: 100%;
          // margin-top: 10vh;
        }

        .files-preview{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;

          .file{
            display: flex;
            flex-direction: column;
            // margin: 10px;
            border: none;
            outline: none;
            position: relative;

            button{
              position: absolute;
              top: 0;
              right: 0;
              background-color: red;
              border: none;
              outline: none;
              height: 25px;
              width: 25px;
              color: $white;
              cursor: pointer;
              // border: 1px solid $white;
            }
          }
        }
      }

      .drop-title {
        color: $white;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        transition: color .2s ease-in-out;
      }
    }
  }
}