@import '../globals.scss';

.download-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba($black, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    width: 60vw;
    height: 60vh;
    background-color: $black;
    border: 2px solid $white;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      width: 90vw;
      height: 70vh;
    }

    &__body {
      &__title {
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 700;
        color: $white;
        margin-bottom: 20px;

        @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
          font-size: 1rem;
        }
      }

      &__description {
        font-size: 1.2rem;
        color: $white;
        margin-bottom: 20px;

        @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
          font-size: .9rem;
        }

        &--disclaimer {
          font-size: 1rem;
          color: $white;
          margin-bottom: 20px;
          opacity: .5;

          @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
            font-size: .8rem;
          }
        }

        a {
          text-decoration: underline;

          &::after {
            content: "\1f855";
            color: $white;
          }
        }
      }

      input {
        width: 50%;
        padding: 10px 20px;
        border: 1px solid $white;
        border-radius: 10px;
        background-color: transparent;
        color: $white;
        font-size: 1rem;
        margin-bottom: 5px;

        @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
          width: 100%;
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
        flex-direction: column;
        gap: 10px;
      }

      &__download,
      &__close {
        padding: 10px 20px;
        border: 1px solid $white;
        border-radius: 10px;
        background-color: transparent;
        color: $white;
        font-size: 1rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        text-decoration: none;

        @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
          width: 100%;
        }

        &:hover {
          background-color: $white;
          color: $black;
        }
      }
    }
  }
}