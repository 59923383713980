@import '../../globals.scss';

.stat-number{
  min-width: 30%;

  &__number{
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  &__loader{
    width: 20px;
    height: 20px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  &:nth-child(2){
    margin-left: 30px;
  }
}