@import '../../globals.scss';

.ressource-details {
  @extend .global-page;

  display: flex;
  flex-direction: column;

  &__download-container {
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
      justify-content: center;
    }

    &__download-button {
      background-color: $white;
      color: $black;
      padding: 10px 20px;
      cursor: pointer;
      border: none;
      outline: none;
      height: 40px;
      font-size: 1rem;
      font-weight: 600;

      @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
        width: 100%;
      }
    }

    select {
      margin-right: 20px;
      padding: 10px 20px 10px 20px;
      border: 1px solid $black;
      cursor: pointer;
      color: $black;
      height: 40px;
      font-size: 1rem;
      font-weight: 600;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        margin-left: 0;
      }

      @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
        width: 100%;
      }

      option {
        color: $black;
        border-radius: 0;
      }
    }
  }
}