@import '../globals.scss';

.ressources {
  @extend .global-page;

  .categories-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 50px;
    align-items: center;
    margin-top: 8vh;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      font-size: 1rem;
      justify-content: space-evenly;
    }
  }
}