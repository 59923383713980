@import '../globals.scss';

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: $navbar-height;

  &__logo {
    width: 20%;

    .home-link{
      text-decoration: none;
      margin: 0;
      padding: 0;
    }


    .title {
      color: $white;
      text-transform: uppercase;
      font-weight: 900;

      @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
        font-size: 1.2rem;
      }
    }
  }

  &__links {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: map-get($breakpoints, 'navbar-special')) {
      display: none;
    }

    .link {
      margin: 0 20px 0 20px;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
      position: relative;

      &:hover::before {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        transform: rotate(-90deg);
        width: 10px;
        height: 10px;
        background-image: url('../../../public/images/icons/arrow-right.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-right: 10px;

      }
      &.dropdown-link{
        // background-color: red;
        &:hover::before {
          left: -15px;
          transform: rotate(0deg);
          top: 3px;
        }
      }

      &.dropdown-button{
        margin-right: 30px;
        &:hover::before {
          display: none;
        }

        &::after{
          content: '';
          display: inline-block;
          position: absolute;
          width: 10px;
          height: 10px;
          top: 1px;
          right: -15px;
          transform: rotate(90deg);
          background-image: url('../../../public/images/icons/arrow-right.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      }
    }
  }

  &__cta {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
      display: none;
    }

    .button-link {
      // margin: 0 20px 0 20px;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
      border: 1px solid $white;
      padding: 8px 10px 5px 10px;
      transition: .3s;

      &:hover::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        background-image: url('../../../public/images/icons/arrow-right.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-right: 10px;
      }
    }
  }

  &__burger {
    display: none;
    vertical-align: middle;

    p {
      margin: 0;
      font-size: 1.2rem;
    }

    @media screen and (max-width: map-get($breakpoints, 'navbar-special')) {
      display: block;
    }
  }

  &__mobile {
    display: none;

    &__active {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $black;
      height: 100vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      overflow-x: hidden;
    }

    .link {
      margin: 20px 0 20px 0;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
      color: $white;
      font-size: 1.2rem;
    }
  }
}