@import '../../globals.scss';

.app-button{
  max-width: 650px;
  width: 100%;
  height: 80px;
  border-radius: 10px;
  display: flex;
  background-color: rgba($white, 0.03);
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba($white, 0.05);
  padding: 10px;
  margin-bottom: 20px;
  position: relative;

  @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
    height: 60px;
  }

  &.variant{
    background-image: var(--button-background);
    background-size: cover;
    background-position: center;
  }

  img{
    max-height: 100%;
  }

  .background-overlay{
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);;
    background-color: rgba($black, 0.6);
    border-radius: 10px;
  }

  .button-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    z-index: 2;

    &.variant{
      margin-left: 0;
    }

    .button-title{
      font-size: 20px;
      font-family: 'Gilroy';
      font-weight: 500;
      text-transform: uppercase;
      margin: 0;

      @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
        font-size: 15px;
      }

      &.variant{
        font-weight: 800;
        font-size: 25px;
      }
    }

    .button-subtitle{
      font-size: 15px;
      font-family: 'AuxMono';
      font-weight: 500;
      opacity: .5;
      margin: 0;

      @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
        font-size: 10px;
      }

      &.variant{
        opacity: 1;
      }
    }
  }
}