@import '../../globals.scss';

.weather-app {
  @extend .global-page;
  padding-top: 0;
  padding-bottom: 10vh;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    height: auto;
  }

  .app-loading{
    @extend .app;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p{
      margin-top: 2vh;
    }
  }

  .app{
    border: 1px solid $white;
    border-radius: 10px;
    // height: 80vh;
    
    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      height: auto;
    }
    
    .header{
      height: 6vh;
      border-bottom: 1px solid $white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      .right, .left{
        display: flex;
        flex-direction: row;
        align-items: center;

        .title{
          margin-bottom: 0;
        }
      }
    }

    .body{
      // height: 74vh;
      display: flex;
      flex-direction: row;
      position: relative;
      flex-wrap: wrap;
      gap: 20px;
      padding: 20px;
      flex-basis: 33.333333%;

      .left, .middle, .right{
        height: 70vh;
        flex: 1;
        gap: 20px;
        display: flex;
        flex-direction: column;
        // min-width: calc(100% / 3 - 20px);
      }
    }
  }
}




