@import '../globals.scss';

.notFound{
  height: 100vh;
  background-color: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title{
    margin-bottom: 30px;
    margin-top: 5vh;
  }

  &__subtitle{
    margin-bottom: 30px;
  }

  &__link{
    font-size: 1.2rem;
    text-decoration: none;
    transition: all .3s;

    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url('../../../public/images/icons/arrow-right.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 10px;
    }
  }
}