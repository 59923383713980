@import '../globals.scss';

.about {
  @extend .global-page;
  padding-bottom: 5vh;

  .content {
    margin-top: 8vh;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      flex-direction: column;
    }

    &__left{
      width: 60%;
      padding-right: 40px;
    }

    &__right{
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
         flex-direction: row;
         margin-top: 5vh;
         flex-wrap: wrap;
         justify-content: space-evenly;
      }

      @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
         justify-content: space-evenly;
      }

      img{
        height: 35vh;
        width: 35vh;
        border: 2px solid $white;
        object-fit: cover;
        margin: 20px;
      }
    }

     &__left, &__right{
      text-align: justify;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        width: 100%;
      }
    }
  }
}