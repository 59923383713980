@import '../../globals.scss';


.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  .countdown{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    background-color: rgba($white, 0.05);
    border: 1px solid rgba($white, 0.1);
    border-radius: 10px;
    padding: 20px;

    img{
      width: 80px;
      height: 80px;
    }

    .values{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 70px;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        gap: 20px;
      }

      .value{
        display: flex;
        flex-direction: column;
        align-items: center;

        .number{
          font-size: 70px;
          font-weight: 800;
          color: $white;
          font-family: 'Gilroy';
          margin: 0;
          padding: 0;
          line-height: 70px;

          @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
            font-size: 40px;
            line-height: 40px;
          }
        }

        .label{
          font-size: 20px;
          font-weight: 800;
          color: $white;
          font-family: 'Gilroy';
          margin: 0;
          padding: 0;

          @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.available-container{
  display: flex;
  flex-direction: column;
  align-items: center;

  .available-title{
    font-size: 30px;
    font-weight: 800;
    color: $white;
    font-family: 'Gilroy';
    margin: 0;
    padding: 0;
    margin-bottom: 10px;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      font-size: 22px;
    }
  }

  img{
    max-width: 300px;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      max-width: 200px;
    }
  }
}