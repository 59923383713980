@import '../../globals.scss';
@import '../../pages/app/app.scss';

.air-item{
  display: flex;
  flex-direction: column;

  &.large{
    align-items: center;
  }

  p{
    margin: 0;
    padding: 0;
  }

  .name{
    font-size: .8rem;

    &.large{
      font-size: 1rem;
      margin-bottom: -10px;
    }
  }

  .value{
    font-size: 1rem;
    font-weight: 800;

    &.large{
      font-size: 2rem;
    }
  }
}
