@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Black.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Thin.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'AuxMono';
  src: url('../fonts/Aux_mono.ttf') format('truetype');
  font-weight: 500;
}