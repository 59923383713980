@import '../../globals.scss';

.auth{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-color: $black;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    height: auto;
    flex-direction: column-reverse;
    padding-top: 5vh;
  }

  .left{
    width: 60%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 20px solid $black;
    border-radius: 60px;
    
    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      background-color: $black;
      width: 100%;
      height: 80vh;
      border: 80px solid $black;
      background-color: $black;
      border-radius: 100px;
    }
  }
  
  .right{
    width: 40%;
    padding: 20px 20px 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      background-color: $black;
      width: 100%;
      padding: 0 80px 0 80px;
    }

    img{
      width: 30%;
    }

    .form{
      margin-top: 20vh;
      padding: 0 20px 0 0;
      display: flex;
      flex-direction: column;
      width: 100%;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      margin-top: 5vh;
      }

      h1{
        margin-bottom: 5vh;
        text-transform: uppercase;
        font-weight: 800;
      }

      input{
        margin-bottom: 20px;
        padding: 7px;
        border: none;
        border-left: 1px solid $white;
        border-bottom: 1px solid $white;
        border-bottom-left-radius: 8px;
        background-color: $black;
        color: $white;
        font-size: 1rem;
        font-weight: 500;
        width: 100%;
        &:focus{
          outline: none;
        }
      }

      button{
        margin-top: 20px;
        padding: 10px;
        border: 1px solid $black;
        border-radius: 8px;
        background-color: $white;
        color: $black;
        font-size: 1rem;
        font-weight: 500;
        width: 100%;
        cursor: pointer;
        outline: none;

        &:hover{
          background-color: $black;
          color: $white;
          border: 1px solid $white;
        }
      }
    }

    .auth-alert{
      margin-top: 20px;
      padding: 10px;
      border-radius: 8px;
      background-color: red;
      color: $white;
      font-size: 1rem;
      width: 100%;
      font-weight: 600;
    }
  }
}