@import '../../globals.scss';
@import '../../pages/app/app.scss';

.moon-main-value{
  display: flex;
  flex-direction: column;

  p{
    margin: 0;
    padding: 0;
  }

  &__title{
    margin-left: 10px;
    text-transform: uppercase;
    font-size: .8rem;

    &.large{
      font-size: 1rem;
    }
  }

  &__value{
    font-size: 1rem;
    font-weight: 800;

    &.large{
      font-size: 1.2rem;
    }

    &.extra{
      font-size: 2rem;
    }
  }
}