@import '../../globals.scss';

.hub-tool-link{
  text-decoration: none;
}

.hub-tool{
  @extend .dashboard-box;
  transition: .3s;
  border: none;
  height: 65vh;
  width: 380px;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 5vh;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  
  &:hover{
    cursor: pointer;
    background-color: rgba($white, .15);
    
    &::before{
      background-color: rgba($black, 0);
    }
    
    .subtitle{
      opacity: .8;
    }
    
    .title{
      opacity: 1;
    }
  }
  
  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($black, .5);
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: .3s;
  }
  
  .title{
    font-size: 1.8rem;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 0;
    color: $white;
    opacity: .9;
    transition: .3s;
  }

  .subtitle {
    font-size: 1.2rem;
    font-weight: 500;
    opacity: .5;
    transition: .3s;
  }

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    height: 30vh;

    &::before{
      display: none;
    }

    .subtitle{
      opacity: 1;
    }
  }
}