@import '../globals.scss';

.contact {
  @extend .global-page;

  &__form {
    width: 40%;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      width: 100%;
    }

    &__group {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;

      input,
      textarea {
        border: none;
        border-bottom: 1px solid $white;
        padding: 10px 0;
        font-size: 1.2rem;
        color: $white;
        background-color: transparent;
        transition: all .3s;

        &:focus {
          outline: none;
          border-bottom: 1px solid $white;
        }
      }
    }

    button {
      border: none;
      background-color: $white;
      color: $black;
      padding: 10px 20px;
      font-size: 1.2rem;
      cursor: pointer;
      transition: all .3s;
      border: 1px solid transparent;

      img {
        max-height: 20px;
        animation: spinning 1s ease-in-out infinite;
      }



      &:hover {
        background-color: transparent;
        color: $white;
        border: 1px solid $white;

        &[disabled] {
          background-color: $white;
          color: $black;
          border: 1px solid transparent;
          cursor: not-allowed;
        }
      }
    }
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}