@import '../globals.scss';

.ressource-display{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 12vh;
  width: 100%;
  border-bottom: 2px solid rgba($white, .3);
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }

  &__left{
    display: flex;
    flex-direction: row;
    // align-items: center;
    width: 80%;
    max-width: 80%;
    height: 100%;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      width: 100%;
      max-width: 100%;
    }

    &__image{
      max-height: 100%;
      margin-right: 20px;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        max-height: 10vh;
      }
    }

    &__text-container{
      max-width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        justify-content: flex-start;
      }

      &__title{
        margin: 0;
        font-size: 1.2rem;
        font-weight: bold;
      }

      &__description{
        position: relative;
        margin: 0;
        font-size: 1.2rem;
        // font-weight: 300;
        // max-width: 100%;
        // white-space: nowrap; /* Prevent text from wrapping to the next line */
        // text-overflow: ellipsis; /* Display an ellipsis (...) when text overflows */
        // overflow: hidden; /* Hide overflowing text */
      }
    }
  }

  &__right{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
  }

    &__button{
      width: 100%;
      padding: 5px;
      background-color: transparent;
      color: $white;
      font-size: 1.2rem;
      border: 1px solid $white;
      cursor: pointer;
      text-decoration: none;
      text-align: center;

      &:hover{
        background-color: $white;
        color: $black;
      }
    }
  }
}