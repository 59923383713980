@import '../../globals.scss';

.news-banner {
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -150px;
  padding: 5px 150px;
  gap: 50px;

  .short-text{
    display: none;
  }

  p, a{
    font-size: 18px;
    color: $black;
    margin: 0;
    padding: 0;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      font-size: 18px;
    }
  }

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    .short-text{
      display: block;
    }

    .long-text{
      display: none;
    }
  }
}