@import '../globals.scss';

.custom-image-display {
  // background-color: red;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item{
    position: relative;
    display: flex;
    flex-direction: column;

    img{
      height: 38vh;
      width: 38vh;
      border: 2px solid $white;
      margin-bottom: 3vh;
      object-fit: cover;
    }

    &__caption{
      position: absolute;
      top: 2px;
      left: 2px;
      background-color: rgba($black, .3);
      color: $white;
      padding: 0.5rem;
      font-size: .8rem;
      font-weight: 700;
    }

    &__date{
      @extend .custom-image-display__item__caption;
      right: 2px;
      left: auto;
    }
  }
}